import { Component } from "react";
import { reduxForm, Field, Fields } from "redux-form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";

import { Button, Paper } from "@material-ui/core";
import {
    RenderRadioGroup,
    RenderTextField,
    RenderFileInput,
    RenderRadio,
} from "../../../../common/components/FormFields";
import validate from "./validate";
import { connect } from "react-redux";
import actionCreators from "../../../../store/UsersAndAudiences/Audiences/actionCreators";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const {
            error,
            submitting,
            handleSubmit,
            currentFormType,
            goBack,
            errors,
            children,
        } = this.props;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === "NEW" ? "Upload new audience" : "Edit audience"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="audienceType"
                            errorRes={errors.audienceType}
                            component={RenderRadioGroup}
                            row
                        >
                            <RenderRadio
                                value="published"
                                label="Available"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="draft"
                                label="Save as draft"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                        </Field>
                        <hr />
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="name"
                                    errorRes={errors.name}
                                    component={RenderTextField}
                                    label="Name"
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <label css={formsCss.label} htmlFor="file">
                                    <br />
                                    Upload a spreadsheet containing only a single column holding LSE
                                    email addresses or IDs{" "}
                                    <small css={formsCss.labelHelp}> (xls or csv)</small>
                                </label>
                                <Fields
                                    names={["file"]}
                                    errorRes={errors.file}
                                    component={RenderFileInput}
                                    onChangeCallback={() => this.props.clearErrors("file")}
                                    onClearCallback={() => this.props.clearErrors("file")}
                                />
                            </div>
                        </div>
                        {error && <strong>{error}</strong>}
                        {children}
                        <div css={formsCss.actions}>
                            <Button
                                disabled={submitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Save Audience
                            </Button>
                            <Button
                                disabled={submitting}
                                onClick={() => goBack()}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const formName = "Audience";
const form = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
})(Form);

const dispatchToProps = {
    clearErrors: actionCreators.clearErrors.create,
};

export default connect(null, dispatchToProps)(form);
