import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MainTitle } from "../../../../common/components/MainTitle";
import CurrentFormTypes from "../../../../common/components/CurrentFormTypes";
import Form from "../Form";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../../common/styles/globals.css";
import { composeAvailabilityRequest } from "../Form/composeRequest.js";
import PromptDialog from "../../../../common/components/PromptDialog";

class OfficeHoursNewTeamGenericAvailabilityContainer extends Component {
    state = {
        request: false,
        initiated: false,
    };

    componentDidMount() {
        const { setTitle, getOneMyOfficeHoursTeam, match, team } = this.props;
        setTitle("Bookings \u203A Manage Availability");

        if (!team) {
            getOneMyOfficeHoursTeam(match.params.teamId);
        } else {
            this.initComponent();
        }
    }

    initComponent() {
        this.setState({ initiated: true });
    }

    componentDidUpdate() {
        const { team } = this.props;
        const { initiated } = this.state;
        if (team && team.id > 0 && !initiated) {
            this.initComponent();
        }
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        const { pushMyOfficeHoursGenericAvailability, team } = this.props;
        const request = composeAvailabilityRequest(values, true);
        this.setState({ request }, () => {
            pushMyOfficeHoursGenericAvailability({
                availability: request,
                action: "validate",
                teamId: team.id,
            });
        });
    };

    handleForceSubmit = confirmed => {
        const { pushMyOfficeHoursGenericAvailability, team, hideConfirmForceRequest } = this.props;
        if (confirmed) {
            pushMyOfficeHoursGenericAvailability({
                availability: this.state.request,
                action: "force",
                teamId: team.id,
            });
        } else hideConfirmForceRequest();
    };

    render() {
        const {
            history: { push },
            team,
            initialValues,
            confirmForceRequest,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        const formInitialValues = team
            ? {
                  ...initialValues,
                  repeating: "no",
                  daily: { every: 1, config: null, ntimes: null, until: null },
                  weekly: {
                      every: 1,
                      config: null,
                      ntimes: null,
                      until: null,
                  },
                  monthly: { every: 1, config: null, ntimes: null, until: null },

                  // teams default settings
                  title: team.officeHoursTitle,

                  location: team.settings.location,
                  appointmentForm: team.settings.appointmentForm,
                  note: team.settings.note,
                  maxNumberOfAttendees:
                      team.settings.maxNumberOfAttendees > 1
                          ? team.settings.maxNumberOfAttendees
                          : 1,
                  maxAppointmentsPerSession:
                      team.settings.maxAppointmentsPerSession > 1
                          ? team.settings.maxAppointmentsPerSession
                          : 1,
                  duration: team.settings.duration,
                  earliestBooking: team.settings.earliestBooking
                      ? team.settings.earliestBooking
                      : 0,
                  latestBooking: team.settings.latestBooking ? team.settings.latestBooking : 0,
                  allowFileUpload: team.settings.allowFileUpload,
                  requireBookingNote: team.settings.requireBookingNote,
              }
            : {};

        return team ? (
            <div>
                <MainTitle title={team.name} type={"[Availability]"} />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Add New Generic Availability</h2>
                        <Form
                            isGenericAvailability={true}
                            {...{
                                initialValues: formInitialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                errors,
                                formError: error,
                                teamId: team ? team.id : null,
                            }}
                        />
                    </div>
                </Paper>

                <PromptDialog
                    open={confirmForceRequest === 1}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "You are changing your online meeting settings, which affects existing booked appointments. Students who have already booked will receive email updates with the updated information. "
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 2}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 3}
                    handleClose={() => this.props.showConfirmForceRequest(1)}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursTeam: actionCreators.getOneMyOfficeHoursTeam.create,
    pushMyOfficeHoursGenericAvailability:
        actionCreators.pushMyOfficeHoursGenericAvailability.create,
    hideConfirmForceRequest: actionCreators.hideConfirmForceRequest.create,
    showConfirmForceRequest: actionCreators.showConfirmForceRequest.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    team: OfficeHoursAvailabilities.team,
    errorRes: OfficeHoursAvailabilities.errorRes,
    initialValues: OfficeHoursAvailabilities.availability,
    confirmForceRequest: OfficeHoursAvailabilities.confirmForceRequest,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(OfficeHoursNewTeamGenericAvailabilityContainer);
