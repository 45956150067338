import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as audienceActionCreators } from "../../../store/UsersAndAudiences/Audiences/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import {
    Card,
    CardHeader,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
} from "@material-ui/core";

import Form from "./components/Form";
import { TableComponent } from "../../../common/components/DefaultTable";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import formsCss from "../../../common/styles/forms.css";
import { AutoRenewIcon } from "../../../common/components/icons";

import tableCss from "../../../common/styles/table.css";

import globalsCss from "../../../common/styles/globals.css";
import { DownloadIcon } from "../../../common/components/icons";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class EditAudienceContainer extends Component {
    state = {
        modalIsOpen: false,
        showMatched: true,
    };

    componentDidMount() {
        const { getOneAudience, match } = this.props;
        this.props.setTitle("Audiences");
        getOneAudience({ id: match.params.id });
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearOneAudience();
    }

    onSubmit = values => {
        this.props.putAudience(values);
    };

    handleReportDownload = () => {
        this.props.downloadReport({ id: this.props.initialValues.id, extension: "xlsx" });
    };

    render() {
        const {
            initialValues,
            errorRes: { error, errors },
            history: { goBack },
            audienceStudentsMatched,
            audienceSstudentsUnmatched,
            getOneAudienceStudentsListMatched,
            getOneAudienceStudentsListUnmatched,
        } = this.props;
        const { modalIsOpen, showMatched } = this.state;
        return (
            <div>
                <MainTitle title="Audiences" type="[Users &amp; Audiences]" />
                <Form
                    currentFormType="EDIT"
                    onSubmit={this.onSubmit}
                    {...{ initialValues, error, errors, goBack }}
                >
                    {initialValues.sourceFileName && (
                        <div className="row">
                            <div className="col-sm-4 col-xs-12" css={globalsCss.helpers.mt25}>
                                <Card>
                                    <CardHeader
                                        action={
                                            <Tooltip
                                                title="Download report"
                                                aria-label="Download report"
                                            >
                                                <IconButton onClick={this.handleReportDownload}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        title={initialValues.sourceFileName}
                                        subheader={
                                            <Fragment>
                                                <div>
                                                    <strong>{initialValues.numStudents}</strong> LSE
                                                    students
                                                    {initialValues.numStudents > 0 && (
                                                        <span>
                                                            {" | "}
                                                            <a
                                                                onClick={() => {
                                                                    getOneAudienceStudentsListMatched(
                                                                        {
                                                                            page: 1,
                                                                            id: initialValues.id,
                                                                        }
                                                                    );
                                                                    this.setState({
                                                                        modalIsOpen: true,
                                                                        showMatched: true,
                                                                    });
                                                                }}
                                                            >
                                                                view
                                                            </a>
                                                        </span>
                                                    )}
                                                </div>

                                                <div>
                                                    <strong>{initialValues.unmatched}</strong>{" "}
                                                    unmatched
                                                    {initialValues.unmatchedExists &&
                                                        initialValues.unmatched > 0 && (
                                                            <span>
                                                                {" | "}

                                                                <a
                                                                    onClick={() => {
                                                                        getOneAudienceStudentsListUnmatched(
                                                                            {
                                                                                page: 1,
                                                                                id:
                                                                                    initialValues.id,
                                                                            }
                                                                        );
                                                                        this.setState({
                                                                            modalIsOpen: true,
                                                                            showMatched: false,
                                                                        });
                                                                    }}
                                                                >
                                                                    view
                                                                </a>
                                                            </span>
                                                        )}
                                                </div>
                                            </Fragment>
                                        }
                                    />
                                </Card>
                            </div>
                        </div>
                    )}
                </Form>

                <Dialog
                    // fullScreen
                    open={modalIsOpen}
                    fullWidth
                    maxWidth={showMatched ? "md" : "sm"}
                    disableBackdropClick
                >
                    <DialogTitle>
                        {showMatched ? "Matched LSE students" : "Unmatched rows"}
                    </DialogTitle>
                    <DialogContent>
                        {(showMatched &&
                            audienceStudentsMatched &&
                            audienceStudentsMatched.count != null) ||
                        (!showMatched &&
                            audienceSstudentsUnmatched &&
                            audienceSstudentsUnmatched.count != null) ? (
                            <div>
                                <TableComponent
                                    items={(showMatched
                                        ? audienceStudentsMatched
                                        : audienceSstudentsUnmatched
                                    ).data.map((i, index) => {
                                        return { ...i, id: index };
                                    })}
                                    decorators={[
                                        {
                                            type: decorateTableItemsJobTypes.TRANSLATE,
                                            payload: showMatched
                                                ? {
                                                      firstname: "First name",
                                                      lastname: "Last name",
                                                      email: "Email address",
                                                  }
                                                : {
                                                      identifier: "Identifier",
                                                  },
                                        },
                                    ]}
                                    columns={
                                        showMatched
                                            ? ["firstname", "lastname", "email", "status"]
                                            : ["identifier", "reason"]
                                    }
                                />
                                {(showMatched
                                    ? audienceStudentsMatched
                                    : audienceSstudentsUnmatched
                                ).nextPage > 0 && (
                                    <div css={tableCss.loadMore}>
                                        <Button
                                            onClick={() => {
                                                showMatched
                                                    ? getOneAudienceStudentsListMatched({
                                                          page: audienceStudentsMatched.nextPage,
                                                          id: initialValues.id,
                                                      })
                                                    : getOneAudienceStudentsListUnmatched({
                                                          page: audienceSstudentsUnmatched.nextPage,
                                                          id: initialValues.id,
                                                      });
                                            }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            <AutoRenewIcon css={formsCss.btnIcon} />
                                            Load more
                                        </Button>
                                        <span css={tableCss.loadMoreLabel}>
                                            <small>
                                                (showing{" "}
                                                {
                                                    (showMatched
                                                        ? audienceStudentsMatched
                                                        : audienceSstudentsUnmatched
                                                    ).data.length
                                                }{" "}
                                                out of{" "}
                                                {
                                                    (showMatched
                                                        ? audienceStudentsMatched
                                                        : audienceSstudentsUnmatched
                                                    ).count
                                                }
                                                )
                                            </small>
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <CircularProgress color="inherit" size={20} thickness={2} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ modalIsOpen: false, showMatched: true });
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const dispatchToProps = {
    getOneAudience: audienceActionCreators.getOneAudience.create,
    clearOneAudience: audienceActionCreators.clearOneAudience.create,
    putAudience: audienceActionCreators.putAudience.create,
    downloadReport: audienceActionCreators.downloadAudienceReport.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneAudienceStudentsListMatched:
        audienceActionCreators.getOneAudienceStudentsListMatched.create,
    loadMoreOneAudienceStudentsListMatched:
        audienceActionCreators.loadMoreOneAudienceStudentsListMatched.create,
    getOneAudienceStudentsListUnmatched:
        audienceActionCreators.getOneAudienceStudentsListUnmatched.create,
    loadMoreOneAudienceStudentsListUNMatched:
        audienceActionCreators.loadMoreOneAudienceStudentsListUNMatched.create,
};
const mapStateToProps = ({ Audience }) => ({
    errorRes: Audience.errorRes,
    initialValues: Audience.audience,
    audienceStudentsMatched: Audience.audienceStudentsMatched,
    audienceSstudentsUnmatched: Audience.audienceSstudentsUnmatched,
});
export default connect(mapStateToProps, dispatchToProps)(EditAudienceContainer);
