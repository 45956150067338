import ActionCreator from "../../actionCreator";

export default {
    getListAudience: ActionCreator("AUDIENCE_GET_LIST"),
    createListAudience: ActionCreator("AUDIENCE_CREATE_LIST"),
    loadMoreAudience: ActionCreator("AUDIENCE_LOAD_MORE"),
    updateListAudience: ActionCreator("AUDIENCE_UPDATE_LIST"),
    getOneAudience: ActionCreator("AUDIENCE_GET_ONE"),
    updateOneAudience: ActionCreator("AUDIENCE_UPDATE_ONE"),
    getOneAudienceStudentsListMatched: ActionCreator("AUDIENCE_GET_ONE_STUDENT_LIST_MATCHED"),
    loadMoreOneAudienceStudentsListMatched: ActionCreator(
        "AUDIENCE_LOAD_MORE_ONE_STUDENT_LIST_MATCHED"
    ),
    updateOneAudienceStudentsListMatched: ActionCreator("AUDIENCE_UPDATE_ONE_STUDENT_LIST_MATCHED"),
    getOneAudienceStudentsListUnmatched: ActionCreator("AUDIENCE_GET_ONE_STUDENT_LIST_UNMATCHED"),
    loadMoreOneAudienceStudentsListUNMatched: ActionCreator(
        "AUDIENCE_LOAD_MORE_ONE_STUDENT_LIST_UNMATCHED"
    ),
    updateOneAudienceStudentsListUnmatched: ActionCreator(
        "AUDIENCE_UPDATE_ONE_STUDENT_LIST_UNMATCHED"
    ),
    clearOneAudience: ActionCreator("AUDIENCE_CLEAR_ONE"),
    putAudience: ActionCreator("AUDIENCE_PUT"),
    postAudience: ActionCreator("AUDIENCE_POST"),
    removeAudience: ActionCreator("AUDIENCE_DELETE"),
    getOneCustomAudience: ActionCreator("CUSTOM_AUDIENCE_GET_ONE"),
    updateOneCustomAudience: ActionCreator("CUSTOM_AUDIENCE_UPDATE_ONE"),
    clearOneCustomAudience: ActionCreator("CUSTOM_AUDIENCE_CLEAR_ONE"),
    getCustomAudienceLiveParams: ActionCreator("CUSTOM_AUDIENCE_GET_LIVE_PARAMS"),
    updateCustomAudienceLiveParams: ActionCreator("CUSTOM_AUDIENCE_UPDATE_LIVE_PARAMS"),
    clearCustomAudienceLiveParams: ActionCreator("CUSTOM_AUDIENCE_CLEAR_LIVE_PARAMS"),
    getCustomAudienceLiveParamsStudents: ActionCreator("CUSTOM_AUDIENCE_GET_LIVE_PARAMS_STUDENTS"),
    updateCustomAudienceLiveParamsStudents: ActionCreator(
        "CUSTOM_AUDIENCE_UPDATE_LIVE_PARAMS_STUDENTS"
    ),
    clearCustomAudienceLiveParamsStudents: ActionCreator(
        "CUSTOM_AUDIENCE_CLEAR_LIVE_PARAMS_STUDENTS"
    ),
    putCustomAudience: ActionCreator("CUSTOM_AUDIENCE_PUT"),
    postCustomAudience: ActionCreator("CUSTOM_AUDIENCE_POST"),
    downloadAudienceReport: ActionCreator("AUDIENCE_DOWNLOAD_REPORT"),
    errorResponse: ActionCreator("AUDIENCE_ERROR_RESPONSE"),
    clearErrors: ActionCreator("CLEAR_AUDIENCE_ERROR"),
    clearQueryParams: ActionCreator("CLEAR_AUDIENCES_PERSISTENT_EVENT"),
};
