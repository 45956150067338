import { of } from "rxjs";
import { mergeMap, catchError, switchMap, tap, mapTo, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { push } from "connected-react-router";
import FileSaver from "file-saver";

const audienceStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListAudience.type,
            actionCreators.loadMoreAudience.type,
            actionCreators.getOneAudience.type,
            actionCreators.putAudience.type,
            actionCreators.postAudience.type,
            actionCreators.getOneCustomAudience.type,
            actionCreators.putCustomAudience.type,
            actionCreators.postCustomAudience.type,
            actionCreators.downloadAudienceReport.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const audienceClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListAudience.type,
            actionCreators.updateListAudience.type,
            actionCreators.updateOneAudience.type,
            actionCreators.updateOneCustomAudience.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListAudienceEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListAudience.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/audience/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.createListAudience.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreAudiencesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreAudience.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/audience/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListAudience.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneAudienceEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneAudience.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/audience/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneAudience.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const audiencePostNewEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postAudience.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("manager/audience/"), action.payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push("/users-and-audiences/audiences")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const audienceEditEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putAudience.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`manager/audience/${action.payload.id}/`), action.payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push("/users-and-audiences/audiences")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const audienceDownloadReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.downloadAudienceReport.type),
        switchMap(action =>
            ajax
                .blob(
                    ajax.apiUrl(
                        `manager/audience/report${action.payload.id}.${action.payload.extension}`
                    )
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `report${action.payload.id}.${action.payload.extension}`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const audienceRemoveEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeAudience.type),
        mergeMap(action =>
            ajax
                .remove(ajax.apiUrl(`manager/audience/${action.payload.id}/`))
                .pipe(
                    mapTo(actionCreators.getListAudience.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneCustomAudienceEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneCustomAudience.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/custom-audience/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateOneCustomAudience.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const customAudiencePostNewEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postCustomAudience.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("manager/custom-audience/"), action.payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push("/users-and-audiences/audiences")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const customAudienceEditEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putCustomAudience.type),
        switchMap(action =>
            ajax
                .put(ajax.apiUrl(`manager/custom-audience/${action.payload.id}/`), action.payload)
                .pipe(
                    concatMap(() =>
                        of(
                            actionCreators.clearQueryParams.create(),
                            UIActionCreators.clearLoading.create(),
                            push("/users-and-audiences/audiences")
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getCustomAudienceLiveParamsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCustomAudienceLiveParams.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl(`manager/custom-audience/live-parameters/`), action.payload).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateCustomAudienceLiveParams.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getCustomAudienceLiveParamsStudentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCustomAudienceLiveParamsStudents.type),
        switchMap(action =>
            ajax
                .post(
                    ajax.apiUrl(
                        `manager/custom-audience/live-lse-students/?page=${action.payload.page}`
                    ),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateCustomAudienceLiveParamsStudents.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneAudienceStudentsListMatchedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneAudienceStudentsListMatched.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`manager/audience/${action.payload.id}/matched-student`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateOneAudienceStudentsListMatched.create({
                                ...response,
                                reset: action.payload.page === 1,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreOneAudienceStudentsListMatchedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOneAudienceStudentsListMatched.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`manager/audience/${action.payload.id}/matched-student`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateOneAudienceStudentsListMatched.create({
                                ...response,
                                reset: action.payload.page === 1,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneAudienceStudentsListUnmatchedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneAudienceStudentsListUnmatched.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`manager/audience/${action.payload.id}/unmatched-student`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateOneAudienceStudentsListUnmatched.create({
                                ...response,
                                reset: action.payload.page === 1,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreOneAudienceStudentsListUNMatchedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOneAudienceStudentsListUNMatched.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`manager/audience/${action.payload.id}/unmatched-student`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateOneAudienceStudentsListUnmatched.create({
                                ...response,
                                reset: action.payload.page === 1,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    audienceStartLoadingEpic,
    audienceClearLoadingEpic,
    getListAudienceEpic,
    loadMoreAudiencesEpic,
    getOneAudienceEpic,
    audiencePostNewEpic,
    audienceEditEpic,
    audienceDownloadReportEpic,
    audienceRemoveEpic,
    getOneCustomAudienceEpic,
    customAudiencePostNewEpic,
    customAudienceEditEpic,
    getCustomAudienceLiveParamsEpic,
    getCustomAudienceLiveParamsStudentsEpic,
    getOneAudienceStudentsListMatchedEpic,
    loadMoreOneAudienceStudentsListMatchedEpic,
    getOneAudienceStudentsListUnmatchedEpic,
    loadMoreOneAudienceStudentsListUNMatchedEpic
);
