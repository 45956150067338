import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MainTitle } from "../../../../common/components/MainTitle";
import CurrentFormTypes from "../../../../common/components/CurrentFormTypes";
import Form from "../Form";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";
import { composeAvailabilityRequest } from "../Form/composeRequest.js";
import PromptDialog from "../../../../common/components/PromptDialog";
import { TextField, MenuItem } from "@material-ui/core";
import { ACADEMIC, TEAM, NONE, MEETING_ZOOM, MEETING_CUSTOM, MEETING_NONE } from "../types";
import { withStyles } from "@material-ui/core/styles";

class OfficeHoursNewTeamAvailabilityContainer extends Component {
    state = {
        request: false,
        isAcademicContext: null,
        memberDetails: null,
        initiated: false,
        template: TEAM,
    };

    componentDidMount() {
        const {
            setTitle,
            getOneMyOfficeHoursAcademic,
            getOneMyOfficeHoursTeam,
            match,
            academic,
            team,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");

        const isAcademicContext = Boolean(
            this.props.location &&
                this.props.location.search &&
                this.props.location.search === "?academic"
        );
        this.setState({ isAcademicContext }, () => {
            if (!team || (isAcademicContext && !academic)) {
                if (isAcademicContext && !academic)
                    getOneMyOfficeHoursAcademic(match.params.teamMemberId);
                if (!team) getOneMyOfficeHoursTeam(match.params.teamId);
            } else {
                this.initComponent();
            }
        });
    }

    initComponent() {
        const { team, getOfficeHoursDefaultAvailability, match } = this.props;
        const memberDetails = this.getTeamMemberDetails(team);
        getOfficeHoursDefaultAvailability(memberDetails.userId);
        this.setState({ initiated: true, memberDetails: memberDetails });
    }

    getTeamMemberDetails = team => {
        const { match, academic } = this.props;
        const { isAcademicContext } = this.state;

        if (!isAcademicContext) {
            for (let user of team.users) {
                for (let userMember of user.officeHoursTeamMembers) {
                    if (
                        userMember.id === Number(match.params.teamMemberId) &&
                        userMember.userType === "member"
                    )
                        return {
                            ...userMember,
                            userId: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                        };
                }
            }
        } else {
            for (let user of team.users) {
                if (user.id === Number(academic.id)) {
                    for (let userMember of user.officeHoursTeamMembers) {
                        if (userMember.teamId === team.id && userMember.userType === "member")
                            return {
                                ...userMember,
                                userId: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                            };
                    }
                }
            }
        }

        return false;
    };

    componentDidUpdate() {
        const { team } = this.props;
        const { initiated } = this.state;
        if (team && team.id > 0 && !initiated) {
            this.initComponent();
        }
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearOfficeHoursDefaultAvailability();
    }

    onSubmit = values => {
        const { pushMyOfficeHoursAvailability, team, academic } = this.props;
        const { isAcademicContext, memberDetails } = this.state;
        const request = composeAvailabilityRequest(values);
        this.setState({ request }, () => {
            pushMyOfficeHoursAvailability({
                availability: request,
                action: "validate",
                teamMemberId: memberDetails.id,
                teamId: isAcademicContext ? null : team.id,
                academicId: isAcademicContext ? academic.id : null,
                isAcademicContext,
            });
        });
    };

    handleForceSubmit = confirmed => {
        const {
            pushMyOfficeHoursAvailability,
            team,
            academic,
            hideConfirmForceRequest,
        } = this.props;
        const { isAcademicContext, memberDetails } = this.state;
        if (confirmed) {
            pushMyOfficeHoursAvailability({
                availability: this.state.request,
                action: "force",
                teamMemberId: memberDetails.id,
                teamId: isAcademicContext ? null : team.id,
                academicId: isAcademicContext ? academic.id : null,
                isAcademicContext,
            });
        } else hideConfirmForceRequest();
    };

    render() {
        const {
            history: { push },
            academic,
            team,
            initialValues,
            confirmForceRequest,
            availabilityTemplate,
            errorRes: { error, errors },
            classes,
        } = this.props;
        const { isAcademicContext, memberDetails, template } = this.state;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        const formInitialValues =
            team && availabilityTemplate
                ? {
                      ...initialValues,
                      repeating: "no",
                      daily: { every: 1, config: null, ntimes: null, until: null },
                      weekly: {
                          every: 1,
                          config: null,
                          ntimes: null,
                          until: null,
                      },
                      monthly: { every: 1, config: null, ntimes: null, until: null },

                      // academic default settings
                      ...(template === ACADEMIC
                          ? {
                                ...availabilityTemplate.settings,
                                onlineMeetings:
                                    !availabilityTemplate.settings.meetingUrl &&
                                    !!availabilityTemplate.settings.integrateWithZoom
                                        ? MEETING_ZOOM
                                        : !!availabilityTemplate.settings.meetingUrl &&
                                          !availabilityTemplate.settings.integrateWithZoom
                                        ? MEETING_CUSTOM
                                        : MEETING_NONE,
                                meetingUrl: availabilityTemplate.settings.meetingUrl,
                                integrateWithZoom: availabilityTemplate.settings.integrateWithZoom,
                            }
                          : {}),

                      // teams default settings?
                      ...(template === TEAM
                          ? {
                                title: team.officeHoursTitle,
                                email: isAcademicContext
                                    ? academic.email
                                    : memberDetails
                                    ? memberDetails.email
                                    : "",

                                location: team.settings.location,
                                appointmentForm: team.settings.appointmentForm,
                                note: team.settings.note,
                                maxNumberOfAttendees:
                                    team.settings.maxNumberOfAttendees > 1
                                        ? team.settings.maxNumberOfAttendees
                                        : 1,
                                maxAppointmentsPerSession:
                                    team.settings.maxAppointmentsPerSession > 1
                                        ? team.settings.maxAppointmentsPerSession
                                        : 1,
                                duration: team.settings.duration,
                                earliestBooking: team.settings.earliestBooking
                                    ? team.settings.earliestBooking
                                    : 0,
                                latestBooking: team.settings.latestBooking
                                    ? team.settings.latestBooking
                                    : 0,
                                allowFileUpload: team.settings.allowFileUpload,
                                requireBookingNote: team.settings.requireBookingNote,

                                addSessionPlaceholders: team.settings.addSessionPlaceholders,
                                addBookedAppointments: team.settings.addBookedAppointments,
                                receiveSummaryEmailAheadUpcomingAppointments: !team.settings
                                    .receiveSummaryEmailAheadUpcomingAppointments,

                                ...(team.settings.atEightOnSessionDay
                                    ? { atEightOnSessionDay: true }
                                    : { atEightOnSessionDay: false }),
                                ...(team.settings.timeInAdvance
                                    ? {
                                          timeInAdvance: true,
                                          timeInAdvanceValue: team.settings.timeInAdvance,
                                      }
                                    : { timeInAdvanceValue: 0 }),
                                ...(team.settings.summaryAtTime
                                    ? {
                                          summaryAtTime: true,
                                          summaryAtTimeValue: team.settings.summaryAtTime,
                                      }
                                    : { summaryAtTimeValue: 0 }),

                                ...(team.settings.atLatestBookingTime
                                    ? { atLatestBookingTime: true }
                                    : { atLatestBookingTime: false }),

                                maxAppointmentsPerUserPerTerm:
                                    team.maxAppointmentsPerUserPerTerm > 0
                                        ? team.maxAppointmentsPerUserPerTerm
                                        : null,
                                maxAppointmentsPerUserPerDay:
                                    team.maxAppointmentsPerUserPerDay > 0
                                        ? team.maxAppointmentsPerUserPerDay
                                        : null,
                                maxAppointmentsPerUserPerWeek:
                                    team.maxAppointmentsPerUserPerWeek > 0
                                        ? team.maxAppointmentsPerUserPerWeek
                                        : null,
                            }
                          : {}),
                  }
                : {};

        return ((isAcademicContext && academic) || memberDetails) && team ? (
            <div>
                <MainTitle
                    title={
                        isAcademicContext
                            ? `${academic.firstName} ${academic.lastName}`
                            : `${memberDetails.firstName} ${memberDetails.lastName}`
                    }
                    type={"[Availability]"}
                />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <div css={globalsCss.rowFlexMiddle}>
                            <h2 css={globalsCss.subtitleTitle}>
                                Add New {isAcademicContext ? " Team " : ""} Availability
                            </h2>

                            <TextField
                                select
                                margin="none"
                                color="primary"
                                value={template}
                                onChange={({ target: { value } }) => {
                                    this.setState({ template: value });
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        error: classes.inputError,
                                    },
                                }}
                            >
                                <MenuItem key={`tpl${TEAM}`} value={TEAM}>
                                    Use team settings
                                </MenuItem>
                                <MenuItem key={`tpl${ACADEMIC}`} value={ACADEMIC}>
                                    Use my default settings
                                </MenuItem>
                                <MenuItem key={`tpl${NONE}`} value={NONE}>
                                    Don&apos;t use saved settings
                                </MenuItem>
                            </TextField>
                        </div>
                        <Form
                            {...{
                                initialValues: formInitialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                errors,
                                formError: error,
                                academicId: isAcademicContext ? academic.id : null,
                                teamId: team ? team.id : null,
                                teamMemberId: isAcademicContext ? null : memberDetails.id,
                            }}
                        />
                    </div>
                </Paper>

                <PromptDialog
                    open={confirmForceRequest === 1}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "You are changing your online meeting settings, which affects existing booked appointments. Students who have already booked will receive email updates with the updated information. "
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 2}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 3}
                    handleClose={() => this.props.showConfirmForceRequest(1)}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic: actionCreators.getOneMyOfficeHoursAcademic.create,
    getOneMyOfficeHoursTeam: actionCreators.getOneMyOfficeHoursTeam.create,
    pushMyOfficeHoursAvailability: actionCreators.pushMyOfficeHoursAvailability.create,
    hideConfirmForceRequest: actionCreators.hideConfirmForceRequest.create,
    showConfirmForceRequest: actionCreators.showConfirmForceRequest.create,
    getOfficeHoursDefaultAvailability: actionCreators.getOfficeHoursDefaultAvailability.create,
    clearOfficeHoursDefaultAvailability: actionCreators.clearOfficeHoursDefaultAvailability.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    academic: OfficeHoursAvailabilities.academic,
    team: OfficeHoursAvailabilities.team,
    errorRes: OfficeHoursAvailabilities.errorRes,
    initialValues: OfficeHoursAvailabilities.availability,
    confirmForceRequest: OfficeHoursAvailabilities.confirmForceRequest,
    availabilityTemplate: OfficeHoursAvailabilities.availabilityTemplate,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(withStyles(formsCss)(OfficeHoursNewTeamAvailabilityContainer));
